<template>
  <div>
    <div>
      <!-- created -->
      <div>
        <template
          v-if="institution && institution.internal_use_id == 'ciisa_uss'"
        >
          <button-add
            v-if="allows_crud"
            @click="$bvModal.show(`add-unit-competence-modal-${matter_id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Agregar ${$getVisibleNames(
                'teaching.tempcompetenceunit',
                false,
                'Unidad de competencia'
              )}`
            "
          >
          </button-add>
          <b-modal
            :id="`add-unit-competence-modal-${matter_id}`"
            hide-footer
            :title="`Agregar ${$getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            )}`"
            size="lg"
          >
            <CompetenceUnitForm
              :matter_id="matter_id"
              @created="$bvModal.hide(`add-unit-competence-modal-${matter_id}`)"
              @close_modal="
                $bvModal.hide(`add-unit-competence-modal-${matter_id}`)
              "
            ></CompetenceUnitForm>
          </b-modal>
        </template>
      </div>
      <div
        v-for="(competence_unit, index) in competenceUnits"
        :key="'CompetenceUnitCell' + competence_unit.id + '-' + matter_id"
        :class="{ competence_cell_block: index + 1 != competenceUnits.length }"
      >
        <div>
          <div class="d-flex">
            {{ competence_unit.order }}.
            <SentenceContainer :Sentence="competence_unit"> </SentenceContainer>
          </div>
          <div>
            <button-edit
              v-if="allows_crud"
              @click="
                $bvModal.show(
                  `edit-unit-competence-modal-${competence_unit.id}-${matter_id}`
                )
              "
              v-b-tooltip.v-secondary.noninteractive.top="
                `Editar ${$getVisibleNames(
                  'teaching.tempcompetenceunit',
                  false,
                  'Unidad de competencia'
                )}`
              "
            ></button-edit>
            <b-modal
              :id="`edit-unit-competence-modal-${competence_unit.id}-${matter_id}`"
              hide-footer
              :title="`Editar ${$getVisibleNames(
                'teaching.tempcompetenceunit',
                false,
                'Unidad de competencia'
              )}`"
              size="lg"
            >
              <CompetenceUnitForm
                :matter_id="matter_id"
                :CompetenceUnit="competence_unit"
                :show_delete_button="true"
                @created="
                  $bvModal.hide(
                    `edit-unit-competence-modal-${competence_unit.id}-${matter_id}`
                  )
                "
                @updated="
                  $bvModal.hide(
                    `edit-unit-competence-modal-${competence_unit.id}-${matter_id}`
                  )
                "
              ></CompetenceUnitForm>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CompetenceUnitCell",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    CompetenceUnitForm: () =>
      import(
        "@/components/teaching/MatterProgram/Components/CompetenceUnitForm"
      ),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      competence_units: names.COMPETENCE_UNITS,
    }),
    competenceUnits() {
      return this.competence_units.filter((x) => x.matter == this.matter_id);
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.competence_cell_block {
  border-bottom: 0.1px solid var(--secondary-color) !important;
}
</style>

